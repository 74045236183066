$(function() {
    initWeekpicker();
});

function initWeekpicker() {
    var selectCurWeek = function() {
        window.setTimeout(function () {
            $('.week-picker').find('.ui-datepicker-current-day a').addClass('ui-state-active')
        }, 1);
    };

    $('.week-picker').datepicker( {
        showOtherMonths: true,
        selectOtherMonths: true,
        dateFormat: "dd-mm-yy",
        firstDay: 1,
        dayNamesMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
        showWeek: true,
        onSelect: function(dateText, inst) {
            var date = $(this).datepicker('getDate');
            //var startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() -1);
            var dateFormat = inst.settings.dateFormat || $.datepicker._defaults.dateFormat;
            var url = parseUrl("from", $.datepicker.formatDate( dateFormat, date, inst.settings ));
            //window.location = url;
            //var planningContainer = $('#content_wrapper.week-planning');
            //var url = parseUrl("from", "<?php echo d(dateAddDays($startDate, 7)); ?>");

            showLoader();

            if (url.indexOf("/plannings/week") >= 0) {
                history.pushState(null, null, url);
                var $planningContainer = $('#content_wrapper.week-planning');

                loadPlanning(url, $planningContainer);
            }
            else {
                window.location = url;
            }

            //// Load planning view
            //$.get(url).then(function (data) {
            //    planningContainer.replaceWith(data);
            //
            //    hideLoader();
            //    initCombobox();
            //    initDatepicker();
            //    loadToolTips();
            //    handleFloatThead();
            //});
        },
        beforeShowDay: function(date) {
            var currentdate = $(this).datepicker('getDate');
            var startDate = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate() - currentdate.getDay() + 1);
            var endDate = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate() - currentdate.getDay() + 7);
            var cssClass = '';
            if(date >= startDate && date <= endDate) {
                cssClass = 'ui-datepicker-current-day';
            }

            return [true, cssClass];
        },
        onChangeMonthYear: function(year, month, inst) {
            selectCurWeek();
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("weekpicker_table fixed");
        }
    });
    $(document).on('mousemove', '.weekpicker_table tr', function() {
        $(this).find('td a').addClass('ui-state-hover');
    });

    $(document).on('mouseleave', '.weekpicker_table tr', function() {
        $(this).find('td a').removeClass('ui-state-hover');
    });
}